/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/_variables';

.app-user-list {
  position: relative;
}
.bottom-20 {
  bottom: 20px;
}
.left-20 {
  left: 20px;
}
.cCrguf {
  background-color: transparent !important;
}
.w-100 {
  width: 100%;
}
.max-w-50{
  max-width: 25%;
}
.min-h-50 {
  min-height: 100%;
}
.min-w-150 {
  min-width: 150px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.mw-280px {
  min-width: 280px;
}
.text-left {
  text-align: left;
}
.mw-50 {
  max-width: 50%;
}
.modal-dialog-backdrop {
  background-color: rgba(34, 41, 47, 0.5);
}
.rdw-wrapper-class {
  border: 1px solid $gray-300;
}
div.rdw-editor-class {
  height: 200px;
}
.company-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  row-gap: 21px;
}
.toast-item .toast-header {
  padding-top: 3px;
  padding-bottom: 3px;
}

.move-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  max-width: 166px;
}
.stop-move-btn {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}
.narrow-btn {
  padding-left: 0.7rem !important;
  padding-right: 0.7rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.no-pointer-events {
  pointer-events: none;
}

.dash-robot {
  background-color: #C75646 !important;
}
.dash-environment {
  background-color: #8EB33B !important;
}
.dash-workspace {
  background-color: #D0B03C !important;
}
.dash-item {
  background-color: #72B3CC !important;
}
.dash-charging {
  background-color: #72B3CC !important;
}
.dash-task {
  background-color: #C8A0D1 !important;
}
.dash-template {
  background-color: #218693 !important;
}
.dash-report {
  background-color: #5D5D5D !important;
}
.dash-audit {
  background-color: #196709 !important;
}
.dash-movement {
  background-color: #9d95f5 !important;
}

.report-list-hover {
  &:hover {
    background-color: #7367f0 !important;
    > * {
      color: #fff !important;
    }
  }
}

.audit-list-hover {
  &:hover {
    background-color: #7367f0 !important;
    > * {
      color: #fff !important;
    }
  }
}

.dashboard-menu-item-disabled {
  background: #ededed;

  & > * {
    color: #c1c1c1 !important;
  }

  & * {
    cursor: default !important;
  }

  &:hover {
    background: #ededed !important;
    > * {
      color: #c1c1c1 !important;
    }
  }
}

.portal-button {
  margin-bottom: 5px;
}

.portal-button-icon {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0.29;
  transform: translate(20%, -50%) scale(3.1);
}

.map-controls {
  right: 1.5rem;
}

.map-zones-controls {
  top: 1rem;
  right: 1.5rem;
}

//.controls-position-fixed .map-controls {
//  position: fixed !important;
//  top: calc(43px + 1.5rem);
//  right: 3.5rem;
//}
//
//.controls-position-fixed .map-zones-controls {
//  position: fixed !important;
//  top: calc(43px + 1.5rem);
//  left: 309px;
//}

.pins {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pin-wrapper {
  position: absolute;
  padding: 5px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.9);
  //transform: translate(-50%, -35%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transform-origin: 50% calc(100% + 8px);
}

.pin-wrapper::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  border: 8px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  transform: translateX(-50%);
}

.highlighted-pin {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px 3px #7367f0;
  border: 4px solid #7367f0;
  border-radius: 5px;
  margin: 0;
}

.highlighted-pin::before {
  border: 11px solid transparent;
  border-top-color: #7367f0;
}

.highlighted-pin-secondary {
  @extend .highlighted-pin;
  box-shadow: 0 0 10px 3px #9E9BF2;
  border: 4px solid #9E9BF2;
}

.date-pickr-cancel {
  right: 8px;
}

.button-loader {
  width: 53px;
}

.button-loader .loading {
  margin-top: 0;
  width: 15px;
  height: 15px;
}

.button-loader .loading .effect-1,
.button-loader .loading .effect-2,
.button-loader .loading .effect-3 {
  width: 15px;
  height: 15px;
  border-left: 2px solid #ffffff;
}

.position-pin {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translate(-50%, 0%);
}

.position-pin-active {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(-50%, 0%);
}

.loader-sm {
  width: 10px;
  height: 10px;
  left: 5px;
  top: 5px;
}

.date-time-picker {
  .flatpickr-hour, .flatpickr-minute {
    pointer-events: none;
  }
  .flatpickr-calendar.static.open {
    top: -320px !important;
    box-shadow: 0 0 20px 5px rgba(0,0,0,0.5) !important;
  }
}

.flatpickr-input[readonly] {
  background-color: $white;
}

.about-text-right {
  text-align: right;
}

.charging-battery-dash {
  .apexcharts-series {
    path:nth-child(even) {
      fill: darken($primary, 40%) !important;
    }
    path:nth-child(odd) {
      fill: $primary !important;
    }
  }
}

.routes-modal-loop-checkbox {
  margin-bottom: 0.6rem;
  padding-right: 2rem;
}

.route-modal-max-width {
  max-width: 1040px;
}

.server-logs-bg {
  background: $theme-dark-bg;
}

.react-dataTable.routes-waypoint-list {
  max-height: 307px;
  overflow-y: scroll;
  overflow-y: overlay;
}

.routes-waypoint-list .rdt_TableHead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.column-order-btns {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.column-order-btns button {
  background: none;
}

.robot-waypoint {
  color: #196709;
}
